.overview-card {
  text-align: center;
  padding: 1em;
  transition: .3s ease-in-out;
  height: 100%;

  .number {
    font-size: 3em;
  }

  .title {
    font-weight: bold;
  }

  &:hover {
    box-shadow: 3px 3px 0.5em #777;
    cursor: pointer;
  }
}

.vulnerability-card {
  text-align: center;
  padding: 1em;
  transition: .3s ease-in-out;
  height: 100%;

  .number {
    font-size: 3em;
  }

  .title {
    font-weight: bold;
  }
}
