$info: #003C71;
$primary: #600;
$warning: #fd7e14;

@import "https://assets.cms.vt.edu/fonts/fonts.css";
@import "~bootstrap/scss/bootstrap";

:root {
  --bs-body-bg: #f4f4f4;
}

body {
  font-family: Acherus, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

h1, h2, h3 {
  font-family: gineso-condensed, sans-serif;

}

.btn.btn-xs {
  padding: 0 3px;
  line-height: 1em;
  border-width: 0px;
  vertical-align: baseline;
}

.card .card-header {
  font-size: 1.2em;
}

.fa-li {
  top: 3px;
}

.embedded-table.table > tbody > tr:last-child > td, .embedded-table.table > tbody > tr:last-child > th {
    border-bottom: 0;
}

table.body-striped > tbody:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.modal-full {
  max-width: 95%;
}


@media (prefers-color-scheme: dark) {
  :root {
    --bs-body-bg: #75787b;
  }

  .card {
    background-color: #333;
    color: #eee;
  }

  .table {
    color: #ddd;
    --bs-table-striped-bg: #444444;
    --bs-table-striped-color: #ddd;
    --bs-table-hover-color: #ddd;
    --bs-table-hover-bg: #555;
    border-color: #808080;
  }

  body {
    color: #eee !important;
  }

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  .btn {
    color: white
  }

  .close-btn {
    color: black;
    background: #fff
  }

  .dropdown-menu {
    background-color: #555;
    
    a.dropdown-item {
      color: #eee;

      &:hover {
        background-color: #777;
      }
    }
  }

  .modal-content {
    background-color: #333;
  }

  .modal-header, .modal-footer {
    border-color: #555;
  }
}

tfoot tr td {
  border: none;
}

.accordion-button {
  background-color: #75787b !important;
  color: white !important;
}

.accordion-button:focus {
  box-shadow: none;
}
