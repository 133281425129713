@keyframes podPending {
  0% {
    border-color: #fcca4d;
  }
  100% {
    border-color: #ff9501;
  }
}

@keyframes podStarting {
  0% {
    border-color: rgb(21, 222, 21);
  }
  100% {
    border-color: green;
  }
}

.pod-display {
  border: 2px solid black;
  padding: .5em;
  margin: .5em;
  display: inline-block;

  p { 
    margin-bottom: 0;
  }

  &.running {
    border-color: green;
  }

  &.succeeded {
    border-color: green;
    background-color: green;
  }

  &.crashed {
    border-color: red;
  }

  &.starting {
    animation-name: podStarting;
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &.pending {
    animation-name: podPending;
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}